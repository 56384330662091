<template>
    <KCourse loader-class="MBcont" course-id="0" title="3 Шага к красивой кукле" :items="items">
         <div class="container">
            <iframe class="video" src="https://www.youtube.com/embed/ZJLbhtL3x2k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>

        <div class="MBtextcont">
            <h3>Анфас</h3>
            <p><b>1. Определите вертикальную и горизонтальную середину головы </b></p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/1.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p class="nomargin"><b> 2. Определите возраст своей куклы и поставьте глаза в соответствии с возрастом:</b></p>
            <ul>
                <li>У взрослой глаза посередине головы</li>
                <li>У подростка посередине головы проходят веки</li>
                <li>У ребенка посередине головы проходят брови</li>
            </ul>
            <p>Между глазами расстояние — один глаз</p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/2.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p class="nomargin"><b>3. Поделите лицо на 3 части:</b></p>
            <ul>
                <li>Определите расположение бровей (выше глаз) </li>
                <li>Отрезок от бровей до подбородка разделите на 2. Это линия носа</li>
                <li>Отрезок от бровей до носа отложите вверх. Это линия роста волос</li>
            </ul>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/3.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p class="nomargin"><b>4. Определите расположение рта</b></p>
            <p>Отрезок от носа до подробородка поделите пополам — середина нижней губы.
</p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/4.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p class="nomargin"><b>5. Проверьте форму головы </b></p>
            <ul>
                <li>Голова расширяется вверх</li>
                <li>Лоб узкий, теменный бугры широкие</li>
                <li>Сверху голова напоминает яйцо</li>
            </ul>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/5.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <h3>Профиль</h3>
            <p><b>1. Перенесите все линии разметки на вид сбоку</b></p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/6.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p class="nomargin"><b>2. Соблюдайте правило 4-ех точек.</b></p>
            <p> Нос, рот, подбородок на одной наклонной</p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/7.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p class="nomargin"><b>3.  Расположение уха</b></p>
            <ul>
                <li>За линией середины</li>
                <li>На уровне бровей и носа</li>
                <li>Под наклоном</li>
            </ul>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/8.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <p><b>4. Профиль помещается в квадрат</b></p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/9.jpg')" alt="" width="100%"/></div>

        <div class="MBtextcont">
            <a class='download-bt' href='/files/Checklist_facemistakes.pdf'>Скачать чек-лист</a>
        </div>
    </KCourse>
</template>

<script>
    import KCourse from "../../../Widgets/KCourse";
    import KImage from "../../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            KCourse,
            KImage
        },
        data() {
        return {
            items: [
                { title: " Пропорции", url:"/mycourses/facemistakes/1"},
                { title: " Детали", url:"/mycourses/facemistakes/2"},
                { title: " Разбор", url:"/mycourses/facemistakes/3"},
            ],
        }
        }
    }
</script>

<style scoped>
    .KImage{ 
        width: 80%;
        margin: 0 auto 50px auto;
        display: block;
    }
    .KImage.mobile, .KImage.laptop{
        width:100%
    }

    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

</style>